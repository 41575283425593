import { useEffect, useRef, useState } from "react";
import StepContainer from "../../../../components/StepContainer";
import StepRoutes from "../../../../constants/Routes";
import { useStore } from "../../../../store/store";
import CheckCard from "../../../../components/CheckCard";
import TextInput from "../../../../components/TextInput";
import Analytics from "../../../../utils/analytics";
import { postEmailValidation, postEnrollment } from "../../../../utils/api";
import { EmailValidationResponse } from "../../../../types/EmailValidationResponse";
import Spinner from "../../../../components/Spinner";
import debounce from "lodash.debounce";
import Modal from "../../../../components/Modal";
import { AseFieldset, AseList } from "../../../../utils/global.styles";
import { TrackingFlowTypeEnum } from "../../../../types/TrackingFlowTypeEnum";

interface FormData {
  [key: string]: { value: string | boolean; error: boolean };
}

const initialFormData = {
  firstName: { value: "", error: true },
  lastName: { value: "", error: true },
  mobilePhone: { value: "", error: true },
  mailAddress: { value: "", error: true },
  contactMe: { value: false, error: true },
} as FormData;

export default function NeedToContactYouStep() {
  const t = useStore.useTranslations();
  const setPayload = useStore.useSetPayload();
  const payload = useStore.usePayload();
  const setRouteStack = useStore.useSetRouteStack();
  const [formData, setFormData] = useState(initialFormData);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isValidatingEmail, setIsValidatingEmail] = useState(false);
  const [emailValidationResponse, setEmailValidationResponse] = useState<EmailValidationResponse>({
    email: "",
    score: 0,
    suggestion: "",
    verdict: "",
  });

  const setValue = (field: string, value: string | boolean, error: boolean) => {
    setFormData({
      ...formData,
      [field]: {
        ...formData[field],
        value,
        error,
      },
    });
  };

  const validateEmail = async (email: string) => {
    if (!email) return;
    try {
      setIsValidatingEmail(true);
      const response = await postEmailValidation(email);
      setEmailValidationResponse(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsValidatingEmail(false);
    }
  };

  const debouncedSearch = useRef(
    debounce(async (email: string) => {
      await validateEmail(email);
    }, 800)
  ).current;

  const handleEmailChange = async (email: string) => {
    debouncedSearch(email);
  };

  useEffect(() => {
    setValue("mailAddress", formData.mailAddress.value, !emailValidationResponse.verdict || emailValidationResponse.verdict === "Invalid");
  }, [emailValidationResponse]);

  console.log("needToContactYou: " + payload)

  return (
    <StepContainer
      stepTitle={payload.occupation === "Student" ? "Vi skal tale med dig" : t?.needToContactYouStep.title}
      progress={1}
      buttonText="Send"
      helpText={t?.needToContactYouStep.helpText}
      onNext={async () => {
        setFormSubmitted(true);

        const hasErrors = Object.values(formData)
          .map((v) => v.error)
          .includes(true);

        if (!hasErrors) {
          Analytics.getInstance().trackNeedToContact(payload, TrackingFlowTypeEnum.STANDRARD);
          // Send lead to backend
          try {
            const contactData = {
              firstName: formData.firstName.value as string,
              lastName: formData.lastName.value as string,
              mobilePhone: formData.mobilePhone.value as string,
              mailAddress: formData.mailAddress.value as string,
              contactMe: formData.contactMe.value as boolean,
            };

            setPayload(contactData);
            setLoading(true);

            await postEnrollment({
              ...payload,
              ...contactData,
            });

            setRouteStack(StepRoutes.CompletedContactYouStep);
          } catch (err) {
            setShowErrorModal(true);
          } finally {
            setLoading(false);
          }
        }
      }}
      nextDisabled={
        Object.values(formData)
          .map((v) => v.error)
          .includes(true) || isValidatingEmail
      }
      loading={loading}
    >
      <AseFieldset>
        {payload.occupation !== "Student" && <p style={{ fontSize: "1.2rem" }}>{t?.needToContactYouStep.description}</p>}
        {payload.occupation === "Student" && <p style={{ fontSize: "1.2rem" }}>For at sikre, at vi er det rette match for dig, har vi brug for at vide lidt mere om din uddannelse. Udfyld dine oplysninger, så ringer vi til dig hurtigst muligt.</p>}
        <AseList>
          <TextInput
            label={t?.informationAboutYouStep.firstName}
            value={formData.firstName.value as string}
            onChange={(e) => {
              setValue("firstName", e.currentTarget.value, e.currentTarget.validity.patternMismatch || !e.currentTarget.value);
            }}
            placeholder={t?.informationAboutYouStep.firstName}
            pattern=".{1,32}"
            id="firstName"
            error={Boolean(formSubmitted && formData.firstName.error) ? t?.informationAboutYouStep.errorFirstName : ""}
          />

          <TextInput
            label={t?.informationAboutYouStep.lastName}
            value={formData.lastName.value as string}
            onChange={(e) => {
              setValue("lastName", e.currentTarget.value, e.currentTarget.validity.patternMismatch || !e.currentTarget.value);
            }}
            placeholder={t?.informationAboutYouStep.lastName}
            pattern=".{1,32}"
            id="lastName"
            error={Boolean(formSubmitted && formData.lastName.error) ? t?.informationAboutYouStep.errorMissingLastName : ""}
          />

          <TextInput
            label={t?.informationAboutYouStep.phone}
            value={formData.mobilePhone.value as string}
            onChange={(e) => {
              setValue("mobilePhone", e.currentTarget.value, e.currentTarget.validity.patternMismatch || !e.currentTarget.value);
            }}
            placeholder={t?.informationAboutYouStep.phone}
            pattern=".{4,32}"
            id="mobilePhone"
            error={Boolean(formSubmitted && formData.mobilePhone.error) ? t?.informationAboutYouStep.errorPhone : ""}
          />

          <TextInput
            label={t?.informationAboutYouStep.mail}
            type="email"
            value={formData.mailAddress.value as string}
            onChange={(e) => {
              setValue("mailAddress", e.currentTarget.value, true);
              handleEmailChange(e.currentTarget.value);
            }}
            hasError={emailValidationResponse.verdict === "Invalid"}
            disableNativeError
            placeholder={t?.informationAboutYouStep.mail}
            id="mailAddress"
            error={emailValidationResponse.verdict === "Invalid" ? t?.informationAboutYouStep.errorEmail : ""}
            postfix={<Spinner show={isValidatingEmail} />}
          />

          {emailValidationResponse.suggestion && <p style={{ color: "var(--color-red)", fontSize: "0.8rem" }}>Mente du: {emailValidationResponse.suggestion}</p>}

          <CheckCard
            id="contactMe"
            titleHtml={t?.needToContactYouStep.consent}
            isChecked={formData.contactMe.value as boolean}
            outlined
            onChange={() => {
              setValue("contactMe", !formData.contactMe.value, !!formData.contactMe.value as boolean);
            }}
          />
        </AseList>
      </AseFieldset>
      <Modal modalDescriptionHtml={t?.needToContactYouStep.errorMessage ?? ""} open={showErrorModal} onClose={() => setShowErrorModal(false)} />
    </StepContainer>
  );
}
